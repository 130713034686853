import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_PROFILE, FORGOT_PASS } from "../../constants";
import { getEmail, saveEmail } from "../../utils/localstorage";
import useLoginRequest from "../hooks/auth/useLoginRequest";
import useForm from "../hooks/utils/useForm";
import Spinner from "../spinner/Spinner";

const Login = (props) => {
  const initialValues = {
    email: "",
    password: "",
    challenge: "",
    remember: false,
    submit: "",
  };
  const passwordRef = useRef();
  const [params] = useSearchParams();
  const [session, setSession] = useState(undefined);
  const { setValues, values, handleChange, handleSubmit, errors, loading } =
    useForm(initialValues);
  const loginRequest = useLoginRequest();

  useEffect(() => {
    if (!loginRequest?.state?.data || session?.active) {
      getLoginRequestFromHydra();
    }
  }, [session?.active, loginRequest?.state?.data === undefined]);

  const redirect = (url) => {
    window.location.href = url;
  };

  const getLoginRequestFromHydra = async () => {
    try {
      const loginChallenge = params.get("login_challenge") || undefined;
      if (loginChallenge) {
        const loginRequestResponse = await loginRequest.fetch(
          loginChallenge,
          session
        );
        if (loginRequestResponse) {
          setValues({
            email: loginRequestResponse?.hint,
            password: "",
            challenge: loginRequestResponse?.challenge,
            remember: false,
            submit: "",
          });
        }
      }
    } catch (err) { }
  };


  const toggleShowPassword = () => {
    const element = passwordRef.current;
    const types = ['password', 'text'];
    if (element) {
      const index = +!types.findIndex(e => e === element.type);
      passwordRef.current.type = types[index]
    }
  }

  const loginForm = (user) => {
    if (!user?.active) {
      return (
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="challenge" value={values?.challenge} />
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-1">
              <label
                className="flex tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                name="email"
                type="email"
                id="email"
                required
                value={values?.hint}
                onChange={handleChange}
                placeholder="something@xyz.com"
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-1">
              <label
                className="flex tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div
                className="appearance-none block w-full relative bg-white text-gray-700   mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <input
                  name="password"
                  id="password"
                  type="password"
                  required
                  ref={passwordRef}
                  onChange={handleChange}
                  value={values?.password}
                  placeholder="********"
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
                <i className='fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600'
                  onClick={toggleShowPassword}
                />
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            <input
              className="mr-2"
              type="checkbox"
              id="remember"
              name="remember"
              value={values?.remember}
              onChange={handleChange}
            />
            <span className="text-gray font-14">Remember me</span>
          </div>
          <div className="flex flex-wrap -mx-3 my-2">
            <div className="w-full px-1">
              <button
                name="submit"
                type="submit"
                className="btn-primary shadow uppercase text-white py-2  rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                value="submit"
                onClick={handleChange}
              >
                Sign in
              </button>
            </div>
            <div className="text-center mt-2 text-sm font-medium">
              <a href={FORGOT_PASS}>
                <span className="text-primary text-center">
                Forgot Password ?
                </span>
              </a>
            </div>
          </div>
        </form>
      );
    }
  };

  const selectAccount = (user) => {
    if (user?.active) {
      const traits = user?.identity?.traits;
      const picture = traits?.picture || DEFAULT_PROFILE;
      const email = getEmail() || traits?.email[0];
      return (
        <div>
          <div
            onClick={() => {
              saveEmail(email);
              setSession(user);
            }}
            className="flex focus:account-selected"
          >
            <div className="w-14 h-14 object-cover mt-0 mb-3">
              <img className="rounded-full" src={picture} alt="user-profile" />
            </div>
            <div className="mt-2 mb-3 ml-3">
              <p>
                {traits?.first_name} {traits?.last_name}
              </p>
              <p className="text-gray-600">{email}</p>
            </div>
          </div>
          <hr />
          <div className="flex flex-wrap -mx-3 my-2 focus:account-selected">
            <div className="w-full px-1">
              <button
                className="uppercase text-gray-500 py-2 px-4 rounded w-full p-2 px-8 mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                value="destroy"
                onClick={async () => {
                  await loginRequest.logout();
                }}
              >
                Use another account
              </button>
            </div>
          </div>
          <hr />
        </div>
      );
    }
  };

  const header = (user, client) => {
    return (
      <div className="mb-3 text-center">
        <div className="p-2 font-medium">
          {user?.active ? (
            <div>
              <p className="text-3xl">Choose an account </p>
              <p className="text-1xl">
                to continue to{" "}
                <a href={client?.client_uri}>{client?.client_name}</a>
              </p>
            </div>
          ) : (
            <p className="text-3xl">Sign in with your sverige.network Account</p>
          )}
        </div>
      </div>
    );
  };

  if (loginRequest.state?.loading || loading) {
    return <Spinner loading={true} />;
  }

  if (loginRequest?.state?.error?.redirect_to) {
    redirect(loginRequest?.state?.error?.redirect_to);
  }
  if (loginRequest?.state?.data?.challenge) {
    const user = loginRequest?.state?.data?.session;
    const client = loginRequest?.state?.data?.client;
    return (
      <div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
        <div className="flex items-center w-full">
          <div
            className="mx-5 md:mx-auto flex p-6 md:w-1/2 xl:w-2/6 w-full"
            style={{ boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)" }}
          >
            <section className="relative w-full pt-25">
              <div className="grid">
                <div className="container mx-auto px-4 h-full">
                  <div className="flex content-center items-center justify-center h-full">
                    <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                      <div className="mb-0 px-5 py-3">
                        {header(user, client)}
                        <hr />

                        <div className="text-left py-4 font-14">
                          {loginForm(user)}
                          {selectAccount(user)}
                        </div>
                      </div>
                      <div className="flex-auto px-4 pt-0">
                        <br />
                        <br />
                        {errors.map((error, key) => (
                          <div key={key}>
                            <span className="text-red-600">{error?.text}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
