import "./App.css";
import { Routes, Route } from "react-router";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Errors from "./components/error/Errors";
import Consent from "./components/consent/Consent";
import Callback from "./components/callback/Callback";
function App() {
  return (
    <Routes>
      <Route exact path="errors" element={<Errors />} />
      <Route exact path="login" element={<Login />} />
      <Route exact path="consent" element={<Consent />} />
      <Route exact path="logout" element={<Logout />} />
      <Route exact path="/oauth/callback" element={<Callback />} />
      {/* <Route exact path="*" element={<Default />} /> */}
    </Routes>
  );
}

export default App;
