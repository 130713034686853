import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getSession } from "../../api/ory/auth";
import useConsentRequest from "../hooks/auth/useConsentRequest";
import Spinner from "../spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft as SwapIcon } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_PROFILE, DEFAULT_SCOPES } from "../../constants";
const scopes = {
  openid: "",
  offline: "",
  email: "Access your email address",
  phone: "Access your contact information",
  address: "Access your address",
  profile: "Access your profile information",
};

const initialValues = {
  challenge: "",
  remember: false,
  submit: "",
  scopes: {},
};

const Consent = (props) => {
  const [params] = useSearchParams();
  const consentRequest = useConsentRequest();
  const [session, setSession] = useState();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (!consentRequest?.state?.data) {
      getConsentRequestFromHydra();
    }
  }, []);

  const getConsentRequestFromHydra = async () => {
    try {
      const consentChallenge = params.get("consent_challenge") || undefined;
      if (consentChallenge) {
        const consentRequestResponse = await consentRequest.fetch(
          consentChallenge
        );
        if (consentRequestResponse) {
          const session = await getSession();
          if (session) {
            setSession(session);
          }
          const scopes = {};
          consentRequestResponse?.requested_scope?.forEach((scope) => {
            scopes[scope] = true;
          });
          setValues({
            challenge: consentRequestResponse?.challenge,
            remember: false,
            submit: "",
            scopes: scopes,
          });
        }
      }
    } catch (err) {}
  };

  const handleChange = (e, value = undefined, checked = undefined) => {
    if (!value) {
      if (e?.target?.value) {
        const { value, name } = e.target;
        setValues((prevState) => {
          return {
            ...prevState,
            [name]: value,
          };
        });
      }

      if (e?.target?.type === "checkbox") {
        const { checked, name } = e.target;
        if (name !== "grant_scope") {
          setValues((prevState) => {
            return {
              ...prevState,
              [name]: checked,
            };
          });
        }
      }
    }
    if (value) {
      setValues((prevState) => {
        return {
          ...prevState,
          scopes: { ...prevState.scopes, [value]: checked },
        };
      });
    }
  };

  if (consentRequest?.state?.loading || !session?.identity) {
    return <Spinner loading={true} />;
  }
  if (consentRequest.state.data) {
    const client = consentRequest?.state?.data?.client || {};
    const requestedScopes = consentRequest?.state?.data?.requested_scope;
    const profile = session?.identity?.traits?.picture || DEFAULT_PROFILE;
    return (
      <div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
        <div className="flex items-center w-full">
          <div
            className="md:mx-auto flex p-6"
            style={{ boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)" }}
          >
            <section className="relative w-full pt-25">
              <div className="grid">
                <div className="container mx-auto px-4 h-full">
                  <div className="flex content-center items-center justify-center h-full">
                    <form
                      onSubmit={async (e) =>
                        await consentRequest.handleSubmit(e, values, session)
                      }
                    >
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5">
                          <div className="flex">
                            {client?.logo_uri && (
                              <div className="w-24 h-24 object-cover text-center mx-auto mt-8 mb-4">
                                <img
                                  className="rounded-full"
                                  src={profile}
                                  alt=""
                                />
                              </div>
                            )}
                            {client?.logo_uri && (
                              <div className="w-24 h-24 object-cover text-center mx-auto mt-14 mb-4">
                                <FontAwesomeIcon
                                  className="text-5xl text-warmGray-300"
                                  icon={SwapIcon}
                                />
                              </div>
                            )}
                            {client?.logo_uri && (
                              <div className="w-24 h-24 object-cover text-center mx-auto mt-8 mb-4">
                                <img
                                  className="rounded-full"
                                  src={client?.logo_uri}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                          <hr className="mt-2 mb-2" />
                          <div className="text-left py-4 font-14">
                            {client?.client_name || "sverige.network"} is asking for
                            permission to :
                          </div>

                          {requestedScopes[0] &&
                            requestedScopes.map((scope, id) => {
                              const isDefaultScope =
                                DEFAULT_SCOPES.includes(scope);
                              const checkedScopes = values.scopes;
                              if (!isDefaultScope) {
                                return (
                                  <div className="mt-3 mb-3" key={id}>
                                    <input
                                      className="mr-2 text-primary focus:checkbox-checked"
                                      type="checkbox"
                                      id={scope}
                                      name="grant_scope"
                                      value={scope}
                                      checked={checkedScopes[scope]}
                                      onChange={(e) => {
                                        handleChange(
                                          undefined,
                                          scope,
                                          e?.target?.checked
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={scope}
                                      className="text-gray font-14"
                                    >
                                      {scopes[scope] || scope}
                                    </label>
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>

                        <div className="mt-3 mb-3 flex-col">
                          {client?.policy_uri && (
                            <div>
                              <a
                                href={client?.policy_uri}
                                className="text-primary text-sm"
                              >
                                Policy
                              </a>
                            </div>
                          )}
                          {client?.tos_uri && (
                            <div>
                              <a
                                href={client?.tos_uri}
                                className="text-primary text-sm"
                              >
                                Terms and conditions
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="mt-3 mb-3">
                          <input
                            className="mr-2 text-primary focus:checkbox-checked"
                            type="checkbox"
                            id="remember"
                            name="remember"
                            value={values?.remember}
                            onChange={handleChange}
                          />
                          <span className="text-gray font-14 ">
                            {" "}
                            Do not ask me again
                          </span>
                        </div>
                        {/* <div className="flex-auto px-4 py-9 pt-0"> */}
                        <div className="flex flex-wrap -mx-3 my-2 justify-between">
                          <div className="px-1">
                            <button
                              id="accept"
                              name="submit"
                              type="submit"
                              className="btn-primary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                              value="Allow"
                              onClick={handleChange}
                            >
                              Allow
                            </button>
                          </div>

                          <div className=" px-1">
                            <button
                              id="reject"
                              name="submit"
                              type="submit"
                              className="btn-secondary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                              value="Deny"
                              onClick={handleChange}
                            >
                              Deny
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
};

export default Consent;
