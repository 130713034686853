const envName = process.env.REACT_APP_NAME || 'local';
const networkConfig = require(`./clients/${envName?.trim()}.json`);
const config = {
  HYDRA_ADMIN_ENDPOINT: networkConfig.hydra_admin,
  HYDRA_PUBLIC_ENDPOINT: networkConfig.hydra_public,
  ORY_API_ENDPOINT: networkConfig.ory_public || networkConfig.hydra_public,
  KETO_API_ENDPOINT: networkConfig.keto_read || networkConfig.hydra_public,
  APP_URL: networkConfig.app_url,
  ORY_TOKEN: networkConfig.ory_token,
  ENV: process.env.REACT_APP_ENV || "development"
};
export default config;
