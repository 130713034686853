import { AdminApi, Configuration, PublicApi } from "@ory/hydra-client";
import config from "../../env";
const baseOptions = {};

if (process.env.MOCK_TLS_TERMINATION) {
  baseOptions.headers = { "X-Forwarded-Proto": "https" };
}
export const hydraAdmin = new AdminApi(
  new Configuration({
    basePath: config.HYDRA_ADMIN_ENDPOINT,
    baseOptions: {
      headers: {
        Authorization: "Bearer " + config.ORY_TOKEN,
      },
    },
  })
);

export const hydraPublic = new PublicApi(
  new Configuration({
    basePath: config.HYDRA_PUBLIC_ENDPOINT,
    baseOptions,
  })
);
