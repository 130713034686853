import { hydraAdmin } from "./index";
import env from "../../env";
import { getPermissions, isAuthorized } from "../keto";
import { getEmail } from "../../utils/localstorage";
import { getSession } from "../ory/auth";

export const getConsentRequest = async (challenge) => {
  try {
    const { data: body } = await hydraAdmin.getConsentRequest(challenge);

    const accessFlag = await isAuthorized(
      body?.client?.client_id,
      body?.subject
    );
    if (!accessFlag) {
      const errorContext =
        "error=access_denied&error_description=You don't have permission to access this site";
      const redirect = env.APP_URL + "/errors?" + errorContext;
      return { redirect_to: redirect };
    }
    if (body.skip) {
      const user = await getSession();
      let session = {
        access_token: {},
        id_token: {},
      };
      const oauthSession = await getOauth2Session(
        user,
        body.requested_scope,
        session
      );
      const acceptRequest = await hydraAdmin.acceptConsentRequest(challenge, {
        grant_scope: body.requested_scope,
        grant_access_token_audience: body.requested_access_token_audience,
        session: oauthSession,
      });
      if (acceptRequest?.data?.redirect_to) {
        return {
          redirect_to: acceptRequest.data.redirect_to,
        };
      }
    }
    return {
      challenge: challenge,
      requested_scope: body.requested_scope,
      user: body.subject,
      client: body.client,
      action: body?.request_url,
    };
  } catch (error) {
    throw error;
  }
};

const isEmailVerified = (user, email) => {
  const emails = user?.identity?.verifiable_addresses;
  if (Array.isArray(emails)) {
    const requestedEmail = emails.find((address) => address.value === email);
    return requestedEmail?.verified || false;
  }
  return false;
};
const getOauth2Session = async (user, grantScope, session) => {
  try {
    if (!user?.active) {
      throw new Error("session_expired");
    }
    const email = getEmail() || user?.identity?.traits?.email[0];

    const permissions = await getPermissions(email);

    const idToken = {
      ory_identity_id: user?.identity?.id,
      default_client: user?.identity?.traits?.default_client,
      wp_user_id: user?.identity?.traits?.wp_user_id,
      wp_username: user?.identity?.traits?.wp_username,
      permissions
    };
    const accessToken = {
      ory_identity_id: user?.identity?.id,
      default_client: user?.identity?.traits?.default_client,
      wp_user_id: user?.identity?.traits?.wp_user_id,
      wp_username: user?.identity?.traits?.wp_username,
      permissions
    };
    if (grantScope.indexOf("email") > -1) {
      idToken.email = email;
      accessToken.email = email;
      idToken.email_verfied = isEmailVerified(user, idToken.email);
      accessToken.email_verified = idToken.email_verfied;
    }
    if (grantScope.indexOf("profile") > -1) {
      accessToken.username = user?.identity?.traits?.username;
      accessToken.picture = user?.identity?.traits?.picture;
      accessToken.nickname = user?.identity?.traits.display_name;
      accessToken.updated_at = user?.identity?.updated_at;
      idToken.username = user?.identity?.traits?.username;
      idToken.nickname = user?.identity?.traits.display_name;
      idToken.picture = user?.identity?.traits?.picture;
    }
    if (grantScope.indexOf("address") > -1) {
      accessToken.address = {
        area: user?.identity?.traits?.address?.area,
        neighbourhood: user?.identity?.traits?.address?.neighbourhood,
      };
      idToken.address = accessToken.address;
    }
    if (grantScope.indexOf("phone") > -1) {
      accessToken.phone = {
        ...user?.identity?.traits?.phone,
      };
      idToken.phone = accessToken.phone;
    }

    return {
      access_token: {
        ...session?.access_token,
        ...accessToken,
      },
      id_token: {
        ...idToken,
        ...session.id_token,
      },
    };
  } catch (error) {
    throw error;
  }
};
export const submitConsentRequest = async (data, user) => {
  try {
    const { challenge, submit, grant_scope, remember } = data;
    if (submit === "Deny") {
      const { data: body } = await hydraAdmin.rejectConsentRequest(challenge, {
        error: "access_denied",
        error_description: "The resource owner denied the request",
      });
      if (body?.redirect_to) {
        return {
          redirect_to: body?.redirect_to,
        };
      }
    } else {
      let session = {
        access_token: {},
        id_token: {},
      };
      const { data: body } = await hydraAdmin.getConsentRequest(challenge);
      const sessionResponse = await getOauth2Session(
        user,
        grant_scope,
        session
      );
      const { data: consentRequest } = await hydraAdmin.acceptConsentRequest(
        challenge,
        {
          grant_scope: grant_scope,
          session: sessionResponse,
          grant_access_token_audience: body.requested_access_token_audience,
          remember: Boolean(remember),
          remember_for: 2592000,
        }
      );
      if (consentRequest?.redirect_to) {
        return { redirect_to: consentRequest?.redirect_to };
      }
    }
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};
