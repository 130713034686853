import env from "../../env";

export const isAuthorized = async (clientId, email) => {
  try {
    if (env.ENV === "development") {
      return true;
    }
    const API = env.KETO_API_ENDPOINT;
    const TOKEN = env.ORY_TOKEN;
    const payload = {
      namespace: "sites",
      object: clientId,
      relation: "access",
      subject_id: email,
    };
    const raw = await fetch(API + "/relation-tuples/check", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await raw.json();
    return res?.allowed || res?.data?.allowed;
  } catch (error) {
    throw error;
  }
};

export const getPermissions = async (email) => {
  try {
    if (env.ENV === "development") {
      return true;
    }
    const API = env.KETO_API_ENDPOINT;
    const TOKEN = env.ORY_TOKEN;
    const apiUrl = `${API}/relation-tuples?subject_id=${encodeURIComponent(email)}&namespace=sites`
    const raw = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
      },
    });
    const res = await raw.json();
    if (res?.relation_tuples) {
      return res.relation_tuples;
    }
    return [];
  } catch (error) {
    throw error;
  }
}
