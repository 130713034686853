import { useState } from "react";
import {
  submitLoginRequest,
  submitWebAuthnLoginRequest,
} from "../../../api/hydra/auth";
import { useNavigate } from "react-router";

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    if (["password", "email", "submit"].includes(e?.target?.type)) {
      const { value, name } = e.target;
      setValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
    if (e?.target?.type === "checkbox") {
      const { checked, name } = e.target;
      setValues((prevState) => {
        return {
          ...prevState,
          [name]: checked,
        };
      });
    }
  };
  const redirect = (obj) => {
    if (obj && obj?.redirect_to) {
      window.location.href = obj.redirect_to;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (!values.email) {
        return;
      }
      setLoading(true);
      const response = await submitLoginRequest(values, navigate);
      if (response) {
        redirect(response);
        if (response?.errors) {
          setErrors(response?.errors);
        }
      }
    } catch (error) {
      redirect(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };
  const webAuthnLogin = async (...params) => {
    try {
      setLoading(true);
      const response = await submitWebAuthnLoginRequest(...params);
      if (response) {
        redirect(response);
        if (response?.errors) {
          setErrors(response?.errors);
        }
      }
    } catch (error) {
      redirect(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };
  return {
    values,
    handleChange,
    setValues,
    handleSubmit,
    errors,
    setErrors,
    loading,
    setLoading,
    webAuthnLogin,
  };
};
export default useForm;
